// wasp.js
import { Config } from "./WASP/Config.js";
import AnimLogo from "./WASP/AnimLogo.js";
import Clock from "./WASP/Clock.js";
import { $, isMobilePhone } from "./WASP/Utils.js";

window.location.pathname === "/"
  ? (document.body.style.overflow = "hidden")
  : (document.body.style.overflowX = "hidden");

let animatedLogo;
let clock;

if (document.querySelector(".wasp-logo-spritesheet")) {
  animatedLogo = new AnimLogo();
  animatedLogo.start();
}

if (!isMobilePhone()) {
  if (document.querySelector(".the-time")) {
    clock = new Clock(Config.SELECTORS.clock);
    clock.start();
    const footer = $("footer");
    footer.style.display = "block";
    setTimeout(() => {
      footer.style.opacity = "1";
    }, 1000);
  }
  if (document.querySelector(".current-year")) {
    $(Config.SELECTORS.year).textContent = new Date().getFullYear().toString();
  }
}

document.addEventListener("DOMContentLoaded", async () => {
  if (document.querySelector(".fader")) {
    $(Config.SELECTORS.fader).classList.add("faded");
  }
});
